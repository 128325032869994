/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 123번째 로그에서는 tailscale VPN, 판교 부동산에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "tailscale VPN"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tailscale.com/"
  }, "Tailscale · Best VPN Service for Secure Networks")), "\n"), "\n", React.createElement(_components.h3, null, "판교 부동산 이야기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://biz.newdaily.co.kr/site/data/html/2021/06/09/2021060900046.html"
  }, "치킨 1위' 교촌, 오산 떠난다… 판교로 본사 이전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.koreadaily.com/news/read.asp?art_id=9455739"
  }, "현대차, 미래차 연구조직 모아 판교 핵심부에 투입한다")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/finance/article/2021061474361"
  }, "맞수 네이버카카오 판교 이웃사촌 된다 - 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/society/article/2021061704031"
  }, "세종·태평양·광장, 몸집 키워 '판교 大戰'")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
